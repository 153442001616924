/* Scoped custom scrollbar styles */
.custom-scrollbar::-webkit-scrollbar {
    width: 5px;
    display: flex;

}

.custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background: #026277;
    border-radius: 6px; /* Rounded edges for the scrollbar thumb */

}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
}


/* General styles */
.custom-scrollbar {
    -ms-overflow-style: auto;  /* IE and Edge */
    scrollbar-width: auto;  /* Firefox */
}




/* Scoped custom scrollbar styles */
.custom-scrollbar-yellow::-webkit-scrollbar {
    width: 5px;
    display: flex;

}

.custom-scrollbar-yellow::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.custom-scrollbar-yellow::-webkit-scrollbar-thumb {
    background: #FDD32E;
    border-radius: 6px; /* Rounded edges for the scrollbar thumb */

}

.custom-scrollbar-yellow::-webkit-scrollbar-thumb:hover {
    background: #555;
}


/* General styles */
.custom-scrollbar-yellow {
    -ms-overflow-style: auto;  /* IE and Edge */
    scrollbar-width: auto;  /* Firefox */
}
