.progress-bar {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 426px; /* You can adjust this width */
}

.step {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #026277; /* Gray color */
    background-color: #fff;
    position: relative;
    font-family: Inter;
}

.step:not(:last-child)::after {
    content: '';
    position: absolute;
    right: -150px; /* Half of step width */
    top: 50%;
    transform: translateY(-50%);
    height: 2px;
    width: 150px; /* This can be adjusted */
    background-color: #026277;
    z-index: -1;
}

.step.completed {
    background-color: #fff;
    border: 2px solid #026277; /* Blue color */
    color: #026277;
    
}

.step.active {
    background-color: #026277;
    border: 2px solid #026277;
    color: #fff;
}

/* style.css */

.step {
    /* Your basic styles for step */
    background-color: #fff;
    border: 2px solid #026277; /* Blue color */
    color: #026277;
}

.completed {
    /* Styles for completed steps, like changing the background color */
    background-color: #fff;
    border: 2px solid #026277; /* Blue color */
    color: #026277;
}

.active {
    /* Styles for the currently active step, maybe a different background color or border */
    background-color: #026277;
    border: 2px solid #026277;
    color: #fff;
}


.input-container {
    position: relative;
}

.input-container input {
    width: 100%;
    padding: 10px;
    margin-left:50px;
    padding-right: 30px;  /* Additional padding to account for the arrow */
    /* Other styles ... */
}

.input-container::after {
    content: 'Enter';  /* Unicode right arrow */
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    font-family: Inter;
    font-size: 20px;
    margin-left:50px;
}