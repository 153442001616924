

/* Global reset or normalize */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Font setup */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');

body, html {
  font-family: 'Inter', sans-serif;
  /* background-color: #f5f5f5; 
  color: #333; Dark grey text */
}
::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for every scrollable element */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Utility classes */
.text-center {
  text-align: center;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

body {
  -webkit-user-select: none;  /* Chrome, Safari */
  -moz-user-select: none;     /* Firefox */
  -ms-user-select: none;      /* IE10+/Edge */
  user-select: none;          /* Standard */
}

