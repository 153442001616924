/* styles.css */

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
    40% { transform: translateY(-5px); }
    60% { transform: translateY(-3px); }
}

.dot {
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: white;
    margin-right: 2px;
    animation: bounce 1.2s linear infinite;
}

.dot1 {
    animation-delay: 0s;
}

.dot2 {
    animation-delay: 0.2s;
}

.dot3 {
    animation-delay: 0.4s;
}

/* Add this to your CSS file or inside a <style> tag */
img {
    max-width: 100%;
    height: auto;
  }
  