

.rpv-default-layout-container{
    font-family: 'Inter';
}


/* This assumes that the toolbar items you want to hide are the second and fourth children */
.rpv-default-layout__toolbar .rpv-toolbar__right .rpv-toolbar__item:nth-child(2),
.rpv-default-layout__toolbar .rpv-toolbar__right .rpv-toolbar__item:nth-child(4) {
    display: none !important;
}

.pdf #toolbar{
    display:none !important;
}