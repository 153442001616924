@keyframes typing {
    from { width: 0; }
    to { width: 100%; }
  }
  
  .typing-effect {
    border-right: 2px solid white; /* Changed to white to be visible on black bg */
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    animation: typing 5s steps(var(--numChars), end) 1s forwards, blink-caret 1s step-end infinite;
    font-size: 50px !important; /* Increased font size */
    color: white; /* Text color changed to white */
    background-color: black; /* Background color set to black */
    padding: 20px; /* Added for some spacing */
  }
  
  @keyframes blink-caret {
    from, to { border-color: transparent; }
    50% { border-color: white; } /* Caret color changed to white */
  }
  body{
    background-color: "black";
  }