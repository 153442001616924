/* Scoped custom scrollbar styles */
.custom-scrollbar::-webkit-scrollbar {
    width: 5px;
    display: flex;

}

.custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background: #026277;
    border-radius: 6px; /* Rounded edges for the scrollbar thumb */

}


.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
}


/* General styles */
.custom-scrollbar {
    -ms-overflow-style: auto;  /* IE and Edge */
    scrollbar-width: auto;  /* Firefox */
}
.custom-scrollbar-general {
    -ms-overflow-style: auto;  /* IE and Edge */
    scrollbar-width: thin; 
     /* Firefox */
}



.helper {
    --reactour-accent: #026277;
    line-height: 1.3;
    padding-top:40px;
    color: #2d2323;
  }

 
  
  /* General styles */
  .custom-scrollbar-wide {
      -ms-overflow-style: auto;  /* IE and Edge */
      scrollbar-width: auto;  /* Firefox */
  }
/* New custom scrollbar styles */
.custom-scrollbar-wide::-webkit-scrollbar {
    width: 10px;
    display: flex;
}

.custom-scrollbar-wide::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.custom-scrollbar-wide::-webkit-scrollbar-thumb {
    background: #026277;
    border-radius: 6px;
}

.custom-scrollbar-wide::-webkit-scrollbar-thumb:hover {
    background: #555;
}



