.table-wrapper {
    max-width: 800px; /* Set to the desired width relative to the parent */
    overflow-x: auto; /* Enable horizontal scrolling */
    max-height: 600px;
}

.table-cell {
    border: 1px solid #ddd; /* Add borders to each cell */
}

.table-header {
    color: "#A0A0A0";
    position: "sticky";
    z-index: 1;
    top: 0;
    background-color: "#FAFAFA";
}
