.quill-editor-container {
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    border: none; /* Remove container border */
    padding: 0; /* Remove container padding */
}

.ql-toolbar.ql-snow {
    position: sticky;
    top: 0;
    z-index: 1;
    background: white;
    border: none; /* Remove toolbar border */
    border-bottom: 1px solid #ccc; /* Optional: add a bottom border to distinguish toolbar */
}

.quill-editor .ql-container {
    flex: 1;
    overflow-y: auto;
    height: calc(100% - 42px); /* Adjust height to account for the toolbar */
    border: none; /* Remove editor container border */
    padding: 0; /* Remove editor container padding */
}

.ql-container.ql-snow {
    border: none; /* Remove border from the editor container */
}

.ql-editor {
    padding: 15px; /* Add padding to the editor content area */
    min-height: 100%; /* Ensure editor takes full height */
    box-sizing: border-box; /* Ensure padding does not affect total height */
}

.quill-editor{
    max-height: 100vh;
}

