/* ReadComponent.css */
@keyframes quiet {
    25% {
        transform: scaleY(.6);
    }
    50% {
        transform: scaleY(.4);
    }
    75% {
        transform: scaleY(.8);
    }
}

@keyframes normal {
    25% {
        transform: scaleY(1);
    }
    50% {
        transform: scaleY(.4);
    }
    75% {
        transform: scaleY(.6);
    }
}

@keyframes loud {
    25% {
        transform: scaleY(1);
    }
    50% {
        transform: scaleY(.4);
    }
    75% {
        transform: scaleY(1.2);
    }
}

.boxContainer {
    background-color: transparent;
    position: absolute;
   
    display: flex;
    justify-content: space-between;
    height: 64px;
    --boxSize: 8px;
    --gutter: 4px;
    width: calc((var(--boxSize) + var(--gutter)) * 5);
}
.container-center {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;

}

.box {
    transform: scaleY(.4);
    height: 100%;
    width: var(--boxSize);
    background: #026277;
    animation-duration: 1.2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    border-radius: 8px;
}

.box1 {
    animation-name: quiet;
}

.box2 {
    animation-name: normal;
}

.box3 {
    animation-name: quiet;
}

.box4 {
    animation-name: loud;
}

.box5 {
    animation-name: quiet;
}

