
  
  /* General styles */
  .scrollbar-onboarding-wide {
      -ms-overflow-style: auto;  /* IE and Edge */
      scrollbar-width: auto;  /* Firefox */
  }
/* New custom scrollbar styles */
.scrollbar-onboarding-wide::-webkit-scrollbar {
    width: 10px;
    display: flex;
}

.scrollbar-onboarding-wide::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.scrollbar-onboarding-wide::-webkit-scrollbar-thumb {
    background: #026277;
    border-radius: 6px;
}

.scrollbar-onboarding-wide::-webkit-scrollbar-thumb:hover {
    background: #555;
}



