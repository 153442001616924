.react-calendar {
  width: 100%;
  background-color: rgba(169, 239, 239, 0.2);
  border-radius: 20px;
  padding: 16px;
  font-family: 'Fira Sans', sans-serif;
}

.react-calendar__navigation {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  border-radius: 15px;
  padding: 8px 16px;
  margin: 0 0 16px 0;
}

.react-calendar__navigation button {
  color: #5FB2C1;
  font-size: 16px;
  background: none;
  border: none;
  min-width: 44px;
  padding: 4px;
}

.react-calendar__navigation__label {
  font-weight: bold;
  text-transform: capitalize;
  flex-grow: 1;
  text-align: center;
  color: #5FB2C1;
}

.react-calendar__navigation__arrow {
  font-size: 20px;
  color: #5FB2C1;
}

/* Make prev/next month arrows more subtle */
.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button {
  color: #5FB2C1;
  opacity: 0.7;
}

/* Hide the double arrows for prev/next year */
.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__month-view__weekdays {
  color: #5FB2C1;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.8em;
}

.react-calendar__month-view__days__day {
  color: #5FB2C1;
  padding: 8px;
  background: none;
  border: none;
  font-size: 14px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.react-calendar__tile--now {
  background: none !important;
  color: #5FB2C1;
}

/* Style for the fire emoji days */
.react-calendar__tile--hasContent {
  position: relative;
  color: #5FB2C1;
}

.react-calendar__tile--hasContent::after {
  content: "🔥";
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 14px;
}

/* Remove default hover effects */
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus,
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: rgba(95, 178, 193, 0.1);
  border-radius: 8px;
}

/* Style for the navigation arrows */
.react-calendar__navigation__arrow {
  font-size: 20px;
  color: #5FB2C1;
}

.react-calendar__navigation__label {
  font-weight: bold;
  text-transform: capitalize;
}

/* Ensure the calendar tile content is centered */
.react-calendar__tile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.75em 0.5em;
  height: 45px;
}

/* Center weekend days */
.react-calendar__month-view__days__day--weekend {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Center the weekday names */
.react-calendar__month-view__weekdays__weekday {
  text-align: center;
  padding: 0.5em;
}

/* Remove the previous streak-day styles and add these new ones */
.streak-day {
  position: relative;
}

.streak-day abbr {
  visibility: hidden;
}

.streak-day::before {
  content: "🔥";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  visibility: visible;
}

/* Ensure proper sizing for tiles */
.react-calendar__tile {
  position: relative;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Remove the previous hasContent styles since we're not using them anymore */
.react-calendar__tile--hasContent::after {
  content: none;
} 