/* Scoped custom scrollbar styles */
.custom-scrollbar::-webkit-scrollbar {
    width: 5px;
    display: flex;

}

.custom-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background: #026277;
    border-radius: 6px; /* Rounded edges for the scrollbar thumb */

}


.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
}


/* General styles */
.custom-scrollbar {
    -ms-overflow-style: auto;  /* IE and Edge */
    scrollbar-width: auto;  /* Firefox */
}
.custom-scrollbar-general {
    -ms-overflow-style: auto;  /* IE and Edge */
    scrollbar-width: thin; 
     /* Firefox */
}

.loading-text::after {
    content: '';
    animation: loading 1.5s steps(3, end) infinite;
}

@keyframes loading {
    0% { content: ''; }
    25% { content: '.'; }
    50% { content: '..'; }
    75% { content: '...'; }
}

